module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__react-dom@18.3.1_maueecnjd3yu7ohfqiy234bdkm/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__3u5hspt6e4nccfuqflrsste75a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
